import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import { HeadingTwo } from 'components/shared/Headings';
import VideoCard from 'components/Contents/VideoCard';
import TrailerModal from 'components/Contents/TrailerModal';

const VideosWrapper = styled.div`
  padding: 5px 0 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  @supports not (gap: 20px) {
    & > a:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  ${({ theme }) => theme.mq.s} {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  ${({ theme }) => theme.mq.lg} {
    grid-template-columns: repeat(3, 1fr);
    gap: 23px;
  }
  ${({ theme }) => theme.mq.xxl} {
    gap: 33px;
  }
`;

const StyledHeadingTwo = styled(HeadingTwo)`
  ${({ homepage }) =>
    homepage &&
    css`
      ${({ theme }) => theme.mq.xl} {
        font-size: ${({ theme }) => `calc(${theme.fontSize.xl} - 1.8rem)`};
      }
      ${({ theme }) => theme.mq.xxl} {
        font-size: 5.6rem;
        padding: ${({ nopadding }) => (nopadding ? '0' : '0 0 25px')};
      }
    `}
`;

const VideosSection = ({ videos, title, homepage }) => {
  const [modal, setModal] = useState({
    url: null,
    visible: false,
  });

  useEffect(() => {
    if (modal.visible) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [modal.visible]);

  return (
    <>
      <TrailerModal
        closeModal={() => setModal({ ...modal, visible: false })}
        visible={modal.visible}
        url={modal.url}
      />
      <Section noBottom id="videos">
        <Content>
          <StyledHeadingTwo
            homepage={homepage}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <VideosWrapper>
            {videos.map(video => (
              <VideoCard
                key={video.title + video.number}
                video={video}
                showModal={({ url }) =>
                  setModal({ ...modal, url, visible: true })
                }
              />
            ))}
          </VideosWrapper>
        </Content>
      </Section>
    </>
  );
};

VideosSection.propTypes = {
  title: PropTypes.string.isRequired,
  videos: PropTypes.arrayOf(PropTypes.object).isRequired,
  homepage: PropTypes.bool,
};

VideosSection.defaultProps = {
  homepage: false,
};

export default VideosSection;
