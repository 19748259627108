import Img from 'gatsby-image/withIEPolyfill';
import styled, { css } from 'styled-components';

const LazyImage = styled(Img).attrs(props => ({
  [props.fixed ? 'fixed' : 'fluid']: props.src,
  objectPosition: props.objectPosition || '50% 50%',
  objectFit: props.cover ? 'cover' : 'contain',
  loading: 'lazy',
}))`
  display: block;
  min-height: ${props => (props.minHeight ? props.minHeight : 'initial')};
  min-width: ${props => (props.minWidth ? `${props.minWidth}px` : 'initial')};
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  height: ${props => (props.height ? `${props.height}px` : '100%')};
  max-height: ${props => (props.maxHeight ? `${props.maxHeight}px` : '100%')};
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : '100%')};
  transition: 0.35s ease;
  ${({ auto }) =>
    auto &&
    css`
      width: auto !important;
      height: auto !important;
    `}
`;

export default LazyImage;
