const scrollToSection = selector => {
  if (selector.includes('.') || selector.includes('#')) {
    document
      .querySelector(`${selector}`)
      .scrollIntoView({ behavior: 'smooth' });
  } else {
    throw new Error('Invalid selector');
  }
};

export const scrollTop = () => {
  if (typeof window !== `undefined`) {
    document.querySelector('#home').scrollIntoView({ behavior: 'smooth' });
  }
};

export default scrollToSection;
