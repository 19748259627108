import styled, { css } from 'styled-components';

export const ControlsWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.35s;
  visibility: hidden;
  opacity: 0;
  z-index: 3;
  background: ${({ playing }) =>
    playing ? 'transparent' : 'rgba(0, 0, 0, 0.4)'};
`;

export const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7) 0,
      rgba(0, 0, 0, 0.2) 60%,
      transparent
    );
    z-index: 1;
    pointer-events: none;
    user-select: none;
    transition: 0.35s;
    opacity: ${({ playing }) => (playing ? '1' : '0.5')};
  }
`;

export const DurationSlider = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  margin: 0 15px 5px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 992px) {
    margin: 0 6px 0 0;
  }
`;

export const DurationSliderSmall = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.3s;
  opacity: 0;
  .ui-video-seek-slider .track .main {
    height: 4px;
    top: 16px;
    @media (max-width: 992px) {
      height: 3px;
      top: 17px;
    }
  }
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
    `}
`;

export const BottomControls = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 15px 15px;
  margin-top: auto;
  ${({ theme }) => theme.mq.lg} {
    padding: 30px;
  }
  @media (max-width: 992px) {
    padding: 0 12px 13px;
  }
  @media (max-width: 440px) {
    padding: 0 5px 5px;
  }
`;

export const VolumeSlider = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 45px;
  left: 0;
  bottom: 50px;
  transition: 0.3s;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
`;

export const VolumeOption = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  @media (max-width: 992px) {
    display: none;
  }
  @media (max-width: 640px) and (orientation: landscape) {
    display: none;
  }
  &:hover > ${VolumeSlider} {
    visibility: visible;
    opacity: 1;
  }
`;

export const OptionButton = styled.button`
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 50px;
  height: 50px;
  @media (hover: hover) {
    &:hover {
      svg {
        rect,
        polygon,
        path {
          fill: ${({ theme }) => theme.colors.primary} !important;
        }
      }
    }
  }
  svg {
    width: 25px;
    height: 25px;
    fill: #fff;
    transition: 0.3s;
    rect,
    polygon,
    path {
      fill: #fff;
      transition: 0.3s;
    }
  }
`;

export const VolumeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  cursor: pointer;
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
  svg {
    width: 25px;
    height: 25px;
    fill: #fff;
    transition: 0.3s;
  }
  @media (max-width: 992px) {
    svg {
      width: 18px !important;
      height: 18px !important;
    }
  }
`;

export const FullScreenButton = styled(VolumeButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

export const Topbar = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 10px 0;
  cursor: pointer;
  ${({ theme }) => theme.mq.lg} {
    padding: 25px 30px 30px;
  }
`;

export const VideoTitle = styled.h2`
  font-size: 2.2rem;
  color: #fff;
  font-weight: 700;
  pointer-events: none;
  user-select: none;
  padding: 10px 10px 0 0;
  @media (min-height: 600px) {
    ${({ theme }) => theme.mq.lg} {
      font-size: 2.8rem;
      padding: 5px 10px 0 0;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MainButtons = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 50px);
  cursor: pointer;
`;

export const PlayButtonMini = styled(OptionButton)`
  @media (max-width: 992px) {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }
`;

export const PlayButton = styled(OptionButton)`
  position: relative;
  width: 50px;
  height: 50px;
  transition: 0.3s;
  opacity: ${({ playing }) => (playing ? '0' : '1')};
  transition-delay: ${({ playing }) => (playing ? '0.3s' : '0s')};
  margin-top: 20px;
  svg {
    width: 35px;
    height: 35px;
  }
  ${({ theme }) => theme.mq.lg} {
    margin-top: 40px;
    margin-top: 0;
    width: 100px;
    height: 100px;
    svg {
      width: 60px;
      height: 60px;
    }
  }
`;

export const MenuButton = styled(OptionButton)`
  position: relative;
  margin-left: 15px;
  ${({ hidden }) =>
    hidden &&
    css`
      svg {
        fill-opacity: 0.4;
      }
      &:hover {
        svg {
          fill-opacity: 1;
        }
      }
    `}
  svg {
    min-width: ${({ chat }) => (chat ? '32px' : '40px')};
    min-height: ${({ chat }) => (chat ? '32px' : '40px')};
  }
  @media (max-width: 991px) {
    display: ${({ chat }) => (chat ? 'none' : 'flex')} !important;
    margin: 0;
    svg {
      min-width: 28px;
      min-height: 28px;
    }
    span {
      display: none !important;
    }
  }
  span {
    display: none;
  }
  @media (hover: hover) {
    span {
      display: block;
      padding: 2px 5px;
      white-space: nowrap;
      position: absolute;
      top: 50%;
      right: 100%;
      z-index: 1;
      transition: 0.3s;
      font-size: 1.4rem;
      font-weight: 500;
      background: #2c2c32;
      pointer-events: none;
      color: #fff;
      border: 1px solid #111;
      opacity: 0;
      transform: scale(0.7) translate(30px, -50%);
      visibility: hidden;
      border-radius: 4px;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    }
    &:hover {
      span {
        opacity: 1;
        visibility: visible;
        transform: scale(1) translate(0, -50%);
      }
    }
  }
`;

export const DurationLabel = styled.div`
  position: absolute;
  top: -6px;
  right: 0;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  font-size: 1rem;
  ${({ theme }) => theme.mq.xs} {
    font-size: 1.2rem;
    top: -10px;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 1.4rem;
  }
`;

export const SettingsButton = styled(OptionButton)`
  position: relative;
  margin-left: -15px;
  margin-right: 5px;
  @media (max-width: 991px) {
    margin: 0;
    width: 30px;
    height: 30px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export const SubtitlesButton = styled(OptionButton)`
  margin-left: -17px;
  margin-right: 8px;
  transition: 0.3s;
  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0.4;
    `}
  @media (max-width: 991px) {
    margin: 0;
    width: 30px;
    height: 30px;
    padding: 0 5px;
  }
`;

export const Options = styled.ul`
  position: absolute;
  bottom: 40px;
  right: 10px;
  display: flex;
  flex-direction: column;
  z-index: 99;
  transition: 0.1s;
  opacity: 0;
  visibility: hidden;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
  ${({ theme }) => theme.mq.xs} {
    bottom: 50px;
  }
`;

export const OptionItem = styled.li`
  padding: 10px 15px;
  font-size: 1.3rem;
  color: #fff;
  transition: 0.3s;
  background: ${({ theme, active }) =>
    active ? theme.colors.primary : '#2c2c32'};
  &:hover {
    background: ${({ theme, active }) =>
      active ? theme.colors.primary : '#505063'};
  }
  ${({ theme }) => theme.mq.xs} {
    font-size: 1.5rem;
    padding: 15px 20px;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;
