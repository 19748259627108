import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({
  description,
  meta,
  title,
  image,
  twitterTitle,
  twitterDesc,
  ogTitle,
  ogDesc,
  robots,
}) {
  const { wpgraphql } = useStaticQuery(
    graphql`
      query {
        wpgraphql {
          page(id: "/strona-glowna/", idType: URI) {
            seo {
              title
              twitterTitle
              opengraphTitle
              metaDesc
              opengraphDescription
              twitterDescription
              opengraphImage {
                sourceUrl
              }
            }
          }
        }
      }
    `
  );

  const metaDescription = description || wpgraphql.page.seo.metaDesc;
  const ogImage = image || wpgraphql.page.seo.opengraphImage;

  const metaOgImage = ogImage
    ? {
        property: `og:image`,
        content: image || ogImage.sourceUrl,
      }
    : {};

  return (
    <Helmet
      htmlAttributes={{
        lang: 'pl',
      }}
      title={title}
      meta={[
        {
          property: `robots`,
          content: robots,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: ogTitle || wpgraphql.page.seo.opengraphTitle || title,
        },
        {
          property: `og:description`,
          content:
            ogDesc ||
            wpgraphql.page.seo.opengraphDescription ||
            metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: `indigital.pl`,
        },
        {
          name: `twitter:title`,
          content: twitterTitle || wpgraphql.page.seo.twitterTitle || title,
        },
        {
          name: `twitter:description`,
          content:
            twitterDesc ||
            wpgraphql.page.seo.twitterDescription ||
            metaDescription,
        },
        {
          name: `twitter:image`,
          content: ogImage,
        },
        metaOgImage,
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  meta: [],
  description: ``,
  image: ``,
  ogTitle: ``,
  ogDesc: ``,
  twitterTitle: ``,
  twitterDesc: ``,
  robots: `index, follow`,
};

SEO.propTypes = {
  description: PropTypes.string,
  ogTitle: PropTypes.string,
  ogDesc: PropTypes.string,
  twitterTitle: PropTypes.string,
  twitterDesc: PropTypes.string,
  image: PropTypes.string,
  robots: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
