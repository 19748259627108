import { useState, useEffect, useRef } from 'react';
import screenfull from 'screenfull';

let count = 0;
let bufferTimeout = null;

const getInitVolume = () => {
  if (typeof window !== `undefined`) {
    const savedSetting = localStorage.getItem('player-volume');
    return savedSetting ? Number(savedSetting) : 1;
  }
  return 1;
};

export const formatTime = (seconds, padStart = 2) => {
  if (!seconds || seconds < 0) return padStart === 1 ? `0:00:00` : `00:00:00`;

  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours().toString().padStart(padStart, '0');
  const mm = date.getUTCMinutes().toString().padStart(2, '0');
  const ss = date.getUTCSeconds().toString().padStart(2, '0');

  return `${hh}:${mm}:${ss}`;
};

const usePlayer = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [state, setState] = useState({
    playing: false,
    controls: false,
    thumb: true,
    muted: false,
    played: 0,
    volume: getInitVolume(),
    seeking: false,
    buffering: false,
  });

  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const controlsRef = useRef(null);
  const { playing, muted, played, volume, buffering, seeking, thumb } = state;

  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing, thumb: false });
    if (state.playing && controlsRef.current) {
      controlsRef.current.style.visibility = 'visible';
      controlsRef.current.style.opacity = '1';
    }
  };

  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  };

  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  };

  const handleProgress = changeState => {
    if (controlsRef.current) {
      if (count > 30) {
        controlsRef.current.style.visibility = 'hidden';
        controlsRef.current.style.opacity = '0';
        count = 0;
      }
      if (controlsRef.current.style.visibility === 'visible') {
        count += 1;
      }
    }
    if (!seeking) {
      setState({ ...state, ...changeState });
    }
  };

  const handleBuffer = () => {
    bufferTimeout = setTimeout(
      () => setState({ ...state, buffering: true }),
      500
    );
  };

  const handleBufferEnd = () => {
    clearTimeout(bufferTimeout);
    bufferTimeout = null;
    setState({ ...state, buffering: false });
  };

  const handleSeekChange = newValue => {
    setState({ ...state, played: parseFloat(newValue) });
    playerRef.current.seekTo(parseFloat(newValue), 'fraction');
  };

  const handleVolumeSeekDown = newValue => {
    setState({ ...state, seeking: false, volume: parseFloat(newValue / 100) });
  };

  const handleVolumeChange = newValue => {
    setState({
      ...state,
      volume: parseFloat(newValue / 100),
      muted: newValue === 0,
    });
    localStorage.setItem('player-volume', parseFloat(newValue / 100));
  };

  const toggleFullScreen = () => {
    screenfull.toggle(playerContainerRef.current);
  };

  const handleMouseMove = () => {
    if (controlsRef.current) {
      controlsRef.current.style.visibility = 'visible';
      controlsRef.current.style.opacity = '1';
      count = 0;
    }
  };

  const handleMouseLeave = () => {
    if (playing && controlsRef.current) {
      controlsRef.current.style.visibility = 'hidden';
      controlsRef.current.style.opacity = '0';
      count = 0;
    }
  };

  const handleMute = () => {
    setState({ ...state, muted: !state.muted });
  };

  const handleKeydowns = e => {
    if (e.keyCode === 32) {
      e.preventDefault();
      handlePlayPause();
    }
    if (e.keyCode === 37) {
      e.preventDefault();
      handleRewind();
      handleMouseMove();
    }
    if (e.keyCode === 39) {
      e.preventDefault();
      handleFastForward();
      handleMouseMove();
    }
  };

  const onControlsClick = () => {
    handlePlayPause();
  };

  const handleVideoEnd = () => {
    setState({ ...state, playing: false });
  };

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on('change', () => {
        setIsFullscreen(screenfull.isFullscreen);
      });
    }
    if (controlsRef.current) {
      controlsRef.current.style.visibility = 'visible';
      controlsRef.current.style.opacity = '1';
    }
  }, []);

  return {
    controlsRef,
    playing,
    buffering,
    played,
    muted,
    isFullscreen,
    volume,
    thumb,
    playerContainerRef,
    playerRef,
    handleSeekChange,
    handleMute,
    handleKeydowns,
    handleMouseMove,
    handleMouseLeave,
    handleVolumeChange,
    handleVolumeSeekDown,
    toggleFullScreen,
    handleProgress,
    handleBuffer,
    handleBufferEnd,
    handleVideoEnd,
    onControlsClick,
  };
};

export default usePlayer;
