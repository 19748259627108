import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from 'components/shared/Icon';
import LangLink from 'components/shared/LangLink';
import logoIcon from 'assets/icons/logo.svg';
import logoInvestIcon from 'assets/icons/logo-invest.svg';
import { scrollTop } from 'utils/scrollToSection';

const Wrapper = styled(LangLink)`
  display: block;
`;

const IconWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Logo = ({ close, to }) => {
  return (
    <Wrapper
      to="/"
      as={to ? 'a' : LangLink}
      href={to}
      target="_blank"
      onClick={() => {
        if (!to) {
          scrollTop();
          close();
        }
      }}
    >
      <IconWrapper>
        <Icon src={to ? logoInvestIcon : logoIcon} alt="logo" full />
      </IconWrapper>
    </Wrapper>
  );
};

Logo.propTypes = {
  to: PropTypes.string,
  close: PropTypes.func,
};

Logo.defaultProps = {
  close: () => null,
  to: '',
};

export default Logo;
