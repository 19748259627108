import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Icon from 'components/shared/Icon';
import igIcon from 'assets/icons/ig.svg';
import fbIcon from 'assets/icons/fb.svg';
import ytIcon from 'assets/icons/yt.svg';
import inIcon from 'assets/icons/in.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 0 0 10px;
  a {
    margin-left: 15px;
  }
  ${({ theme }) => theme.mq.s} {
    padding: 0 50px 0 20px;
    a {
      margin-left: 35px;
    }
  }
`;

const getIcon = type => {
  switch (type) {
    case 'instagram':
      return igIcon;
    case 'facebook':
      return fbIcon;
    case 'youtube':
      return ytIcon;
    case 'linkedin':
      return inIcon;
    default:
      return null;
  }
};

const SocialMedia = () => {
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        page(id: "/strona-glowna/", idType: URI) {
          PageHome {
            bannerSocialMedia {
              bannerSocialMediaType
              bannerSocialMediaLink
            }
          }
        }
      }
    }
  `);

  const socialMedia = data.wpgraphql.page.PageHome.bannerSocialMedia
    .map(({ bannerSocialMediaType, bannerSocialMediaLink }) => ({
      type: bannerSocialMediaType,
      link: bannerSocialMediaLink,
    }))
    .filter(({ link }) => link);

  return (
    <Wrapper>
      {socialMedia.map(({ type, link }) => (
        <a href={link} target="_blank" rel="noreferrer">
          <Icon src={getIcon(type)} alt={type} size={16} />
        </a>
      ))}
    </Wrapper>
  );
};

export default SocialMedia;
