/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Content from 'components/shared/Content';
import useOutsideClick from 'hooks/useOutsideClick';
import Player from 'components/Player/Player';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transform: translate(-100vw, 0);
  transition: 0.35s ease;
  ${({ theme }) => theme.mq.xs} {
    transform: translate(0, 60px);
  }
  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0) !important;
    `}
  & > div {
    @media (max-width: 439px) {
      padding: 0;
    }
  }
`;

const PlayerWrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: 90vh;
  overflow: hidden;
  &:after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 45px;
  height: 45px;
  z-index: 1;
  svg {
    width: 14px;
    height: 14px;
  }
  ${({ theme }) => theme.mq.xl} {
    top: 25px;
    right: 25px;
    width: 60px;
    height: 60px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
  background: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  svg {
    transition: 0.3s;
    fill: ${({ theme }) => theme.colors.dark};
  }
`;

const TrailerModal = ({ closeModal, visible, url }) => {
  const modalRef = useRef(null);
  useOutsideClick(modalRef, closeModal);

  return (
    <Overlay isVisible={visible}>
      <Wrapper isVisible={visible}>
        <Content short>
          <PlayerWrapper ref={modalRef}>
            <Player
              video={{
                url: visible ? `${url}&autoplay=1` : '',
              }}
            />
          </PlayerWrapper>
        </Content>
      </Wrapper>
      <CloseButton onClick={closeModal} aria-label="zamknij">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 22 22"
        >
          <path
            d="M12.215,11l9.533-9.533A.859.859,0,1,0,20.533.252L11,9.785,1.467.252A.859.859,0,0,0,.251,1.468L9.784,11,.251,20.534a.859.859,0,0,0,1.215,1.215L11,12.216l9.533,9.533a.859.859,0,0,0,1.215-1.215Z"
            transform="translate(0 -0.001)"
          />
        </svg>
      </CloseButton>
    </Overlay>
  );
};

TrailerModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
};

export default TrailerModal;
