/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { LangContext } from 'contexts/LangContext';

const TRANSLATIONS = [
  ['/architekci-kubatury', '/en/architects'],
  ['/od-idei-do-realizacji', '/en/from-idea-to-implementation'],
  ['/architekci-wnetrz', '/en/interior-designers'],
  ['/jakosc-w-kazdym-detalu-2', '/en/quality-in-every-detail'],
  ['/rzemieslnicy', '/en/craftsmen'],
  ['/najwyzszej-klasy-budownictwo', '/en/top-class-construction'],
  ['/artysci', '/en/artists'],
  ['/architekci-krajobrazu', '/en/landscape-designers'],
];

const LangLink = ({ to, children, langButton, ...props }) => {
  const { lang } = useContext(LangContext);
  let translatedTo = '/';

  if (langButton) {
    const pathname =
      typeof window !== `undefined` ? window.location.pathname : '/';
    if (langButton !== lang) {
      let translationPair = null;

      // eslint-disable-next-line no-restricted-syntax
      for (const translation of TRANSLATIONS) {
        if (pathname === translation[0]) {
          [, translationPair] = translation;
        } else if (pathname === translation[1]) {
          [translationPair] = translation;
        }
      }

      if (translationPair !== null) {
        translatedTo = translationPair;
      } else {
        translatedTo =
          lang !== 'en' ? `/en${pathname}` : pathname.replace('/en/', '/');
      }
    } else {
      translatedTo = pathname;
    }
  } else {
    translatedTo = lang === 'en' ? `/en${to.replace('en_', '')}` : to;
  }

  return (
    <Link to={translatedTo} {...props}>
      {children}
    </Link>
  );
};

LangLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  langButton: PropTypes.string,
};

LangLink.defaultProps = {
  to: '/',
  langButton: null,
};

export default LangLink;
