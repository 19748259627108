import styled, { css } from 'styled-components';

const Section = styled.section`
  position: relative;
  text-align: ${({ align }) => align || 'left'};
  padding: ${({ padding }) => padding || '30px 0'};
  ${({ theme }) => theme.mq.xs} {
    padding: ${({ padding }) => padding || '40px 0'};
  }
  ${({ theme }) => theme.mq.lg} {
    padding: ${({ padding }) => padding || '70px 0'};
  }
  ${({ noTop }) =>
    noTop &&
    css`
      padding-top: 0 !important;
    `}
  ${({ noBottom }) =>
    noBottom &&
    css`
      padding-bottom: 0 !important;
    `}
  ${({ big }) =>
    big &&
    css`
      ${({ theme }) => theme.mq.xl} {
        padding: ${({ padding }) => padding || '100px 0'};
      }
    `}
`;

export default Section;
