import React, { useState, useEffect, useMemo, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LazyImage from 'components/shared/LazyImage';
import LangLink from 'components/shared/LangLink';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-right-small.svg';
import { LangContext } from 'contexts/LangContext';

const VideoCardInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px 0 10px;
  ${({ theme }) => theme.mq.lg} {
    padding: 25px 0 10px;
  }
`;

const VideoTitle = styled.h3`
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.4;
  br {
    display: none;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.2rem;
  }
`;

const Time = styled(VideoTitle)`
  color: #ccc;
  font-weight: 300;
  white-space: nowrap;
  text-transform: uppercase;
`;

const ThumbImage = styled.div`
  width: 100%;
  height: 100%;
  transition: transform 0.7s ease 0s;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
`;

const Wrapper = styled(LangLink)`
  display: flex;
  flex-direction: column;
  color: #fff;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  &:hover {
    button {
      opacity: 1;
    }
    ${ThumbImage} {
      transform: scale(1.05);
    }
  }
`;

const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
`;

const Thumbnail = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  ${({ theme }) => theme.mq.s} {
    height: 230px;
  }
  ${({ theme }) => theme.mq.xl} {
    height: 280px;
  }
  ${({ theme }) => theme.mq.xxl} {
    height: 320px;
  }
  overflow: hidden;
  z-index: 1;
  @media (max-width: 1360px) and (min-width: 575px) {
    &:before {
      content: none;
    }
  }
`;

const ThumbnailWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  cursor: pointer;
`;

const ThumbnailInnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%);
  background: transparent;
  z-index: 25;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const Description = styled.div`
  padding: 10px 0;
  width: 100%;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
  line-height: 1.4;
  ${({ theme }) => theme.mq.md} {
    /* font-size: 1.9rem; */
    padding: 10px 0;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 10px 60px 15px 0;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.2rem;
    padding: 20px 80px 20px 0;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  margin: 10px 0 20px;
  width: 100%;
  max-width: max-content;
  text-transform: uppercase;
  color: #fff;
  background: transparent;
  white-space: nowrap;
  font-size: 1.3rem;
  font-family: ${({ theme }) => theme.fonts.mainFont};
  border: 1px solid #ffffff;
  opacity: 0.7;
  transition: 0.3s;
  min-height: 28px;
  ${({ theme }) => theme.mq.xxl} {
    font-size: 1.8rem;
    min-height: 35px;
  }
  div {
    padding: 0 5px;
    ${({ theme }) => theme.mq.md} {
      padding: 0 15px;
    }
  }
  span {
    display: none;
    ${({ theme }) => theme.mq.md} {
      margin-right: 10px;
      display: block;
    }
  }
`;

const InfoOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
`;

const PremierInfo = styled(Time)`
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
`;

const TrailerInfo = styled(Time)`
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
`;

const TimeInfo = styled.time`
  font-size: 4rem;
  font-weight: 500;
  ${({ theme }) => theme.mq.xxl} {
    font-size: 6rem;
  }
`;

const DaysInfo = styled(TimeInfo)`
  font-size: 2.4rem;
  margin-top: 10px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;
  @media (min-width: 380px) {
    margin-top: 0;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 4rem;
  }
`;

let timeout = null;

const VideoCard = ({ video, showModal }) => {
  const { translate } = useContext(LangContext);

  const difference = useMemo(
    () => (video.premierDate - new Date()) / 1000,
    [video.premierDate]
  );

  const [isDisabled, setIsDisabled] = useState(false);
  const [counter, setCounter] = useState(difference);

  useEffect(() => {
    if (counter > 0) {
      timeout = setTimeout(() => setCounter(counter - 1), 1000);
    }
    return () => clearTimeout(timeout);
  }, [counter]);

  useEffect(() => {
    setCounter(difference);
  }, [difference]);

  const daysDiff = Math.ceil(counter / (3600 * 24));

  useEffect(() => {
    setIsDisabled(daysDiff > 0);
  }, [daysDiff]);

  return (
    <Wrapper
      as={isDisabled ? 'div' : LangLink}
      onClick={() =>
        isDisabled && video.trailer && showModal({ url: video.trailer })
      }
      disabled={isDisabled && !video.trailer}
      to={`/${video.slug}`}
    >
      <Thumbnail>
        <ThumbnailWrapper>
          <ThumbnailInnerWrapper>
            <ThumbImage disabled={isDisabled}>
              <LazyImage src={video.thumbnail} alt="thumbnail" cover />
            </ThumbImage>
            {!isDisabled ? (
              <PlayButton aria-label="play video">
                <PlayIcon />
              </PlayButton>
            ) : (
              <>
                <PremierInfo as="div">{translate('premiereIn')}</PremierInfo>
                <InfoOverlay>
                  <DaysInfo>
                    {daysDiff}
                    {daysDiff === 1
                      ? ` ${translate('oneDay')}`
                      : ` ${translate('coupleDays')}`}
                  </DaysInfo>
                </InfoOverlay>
                {video.trailer && (
                  <TrailerInfo as="div">{translate('seeTrailer')}</TrailerInfo>
                )}
              </>
            )}
          </ThumbnailInnerWrapper>
        </ThumbnailWrapper>
      </Thumbnail>
      <CardInner disabled={isDisabled}>
        <VideoCardInfo>
          <VideoTitle
            dangerouslySetInnerHTML={{
              __html: `${video.title}`,
            }}
          />
          <Time as="time">{video.duration}</Time>
        </VideoCardInfo>
        <Description dangerouslySetInnerHTML={{ __html: video.excerpt }} />
        <div>
          <Button>
            <span>{translate('viewMore')}</span>
            <div>
              <ArrowIcon />
            </div>
          </Button>
        </div>
      </CardInner>
    </Wrapper>
  );
};

VideoCard.propTypes = {
  video: PropTypes.objectOf(PropTypes.any).isRequired,
  showModal: PropTypes.func.isRequired,
};

export default VideoCard;
