/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ReactPlayer from 'react-player';
import Controls from 'components/Player/Controls';
import Loader from 'components/shared/Loader';
import usePlayer from 'hooks/usePlayer';
import LazyImage from 'components/shared/LazyImage';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  background: #000;
  &:before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 56.25%;
  }
`;

const MainPlayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  video::-webkit-media-text-track-container {
    transform: translateY(-40px) !important;
    ${({ theme }) => theme.mq.lg} {
      transform: translateY(-75px) !important;
    }
  }

  /* iframe {
    position: relative;
    z-index: -1;
  } */
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  width: 50px;
  height: 50px;
  margin-top: -10px;
  ${({ theme }) => theme.mq.lg} {
    width: 80px;
    height: 80px;
    margin-top: -20px;
  }
`;

const ThumbnailWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  cursor: pointer;
  transition: 0.7s;
  opacity: 0;
  visibility: hidden;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const ThumbImage = styled.div`
  width: 100%;
  height: 100%;
  transition: transform 0.7s ease 0s;
`;

const ThumbnailInnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  &:hover > ${ThumbImage} {
    transform: scale(1.05);
  }
`;

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%);
  background: transparent;
  z-index: 25;
  cursor: pointer;
  ${({ theme }) => theme.mq.lg} {
    width: 130px;
    height: 130px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

const Player = ({ video }) => {
  const {
    controlsRef,
    playing,
    buffering,
    thumb,
    muted,
    isFullscreen,
    volume,
    playerContainerRef,
    playerRef,
    handleSeekChange,
    handleMute,
    handleKeydowns,
    handleMouseMove,
    handleMouseLeave,
    handleVolumeChange,
    handleVolumeSeekDown,
    toggleFullScreen,
    handleProgress,
    handleBuffer,
    handleBufferEnd,
    handleVideoEnd,
    onControlsClick,
  } = usePlayer();

  const videoDOM =
    playerRef.current && playerRef.current.wrapper.querySelector('video');

  const [subtitlesMode, setSubtitlesMode] = useState('showing');
  const [activeQualityIndex, setActiveQualityIndex] = useState(0);
  const playerURL =
    video.url instanceof Array ? video.url[activeQualityIndex].url : video.url;

  const toggleSubtitles = () => {
    if (subtitlesMode === 'showing') setSubtitlesMode('disabled');
    else setSubtitlesMode('showing');
  };

  useEffect(() => {
    if (videoDOM && video.subtitles && subtitlesMode) {
      const track = video.querySelector('track');
      if (track) track.track.mode = subtitlesMode;
    }
  }, [subtitlesMode]);

  useEffect(() => {
    if (videoDOM && video.subtitles) {
      [...videoDOM.children].forEach(el => el.remove());
      fetch(video.subtitles)
        .then(response => response.text())
        .then(result => {
          const blob = new Blob([result], {
            type: 'text/vtt',
          });
          const track = document.createElement('track');
          Object.assign(track, {
            kind: 'subtitles',
            label: 'pl',
            default: true,
            mode: subtitlesMode,
            src: window.URL.createObjectURL(blob),
          });
          videoDOM.appendChild(track);

          const delayParam = video.subtitles.match(/delay=([^&]*)/);
          const delay =
            delayParam && delayParam[1] ? Number(delayParam[1]) : null;
          if (delay) {
            track.addEventListener('load', () => {
              if (track.track.cues) {
                Array.from(track.track.cues).forEach(cue => {
                  cue.startTime += delay;
                  cue.endTime += delay;
                });
              }
            });
          }
        })
        .catch(error => console.log('error', error));
    }
  }, [video.subtitles, videoDOM]);

  return (
    <Wrapper
      ref={playerContainerRef}
      onKeyDown={handleKeydowns}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      tabIndex="0"
    >
      <MainPlayer>
        <ReactPlayer
          key={playerURL}
          ref={playerRef}
          width="100%"
          height="100%"
          url={playerURL}
          playIcon={<></>}
          playing={playing}
          light={false}
          volume={volume}
          muted={muted}
          onProgress={handleProgress}
          onBuffer={handleBuffer}
          onBufferEnd={handleBufferEnd}
          onReady={handleBufferEnd}
          onEnded={handleVideoEnd}
          progressInterval={100}
          config={{
            file: {
              attributes: {
                preload: 'auto',
                controls: '',
              },
            },
            youtube: {
              playerVars: {
                rel: 0,
                controls: 1,
                disablekb: 1,
                modestbranding: 1,
                listType: 'playlist',
                start: 0,
              },
            },
          }}
        />
      </MainPlayer>

      {/* {playing && buffering && (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      )} */}

      {video.thumbnail && (
        <ThumbnailWrapper visible={thumb} onClick={onControlsClick}>
          <ThumbnailInnerWrapper>
            <ThumbImage>
              <LazyImage src={video.thumbnail} alt="thumbnail" cover />
            </ThumbImage>
            <PlayButton aria-label="play video">
              <PlayIcon />
            </PlayButton>
          </ThumbnailInnerWrapper>
        </ThumbnailWrapper>
      )}

      {/* <Controls
        ref={controlsRef}
        onSeek={handleSeekChange}
        onControlsClick={onControlsClick}
        playing={playing}
        buffering={buffering}
        onMute={handleMute}
        muted={muted}
        onVolumeChange={handleVolumeChange}
        onVolumeSeekDown={handleVolumeSeekDown}
        onToggleFullScreen={toggleFullScreen}
        fullscreen={isFullscreen}
        volume={volume}
        activeQualityIndex={activeQualityIndex}
        setActiveQualityIndex={setActiveQualityIndex}
        videoURL={video.url}
        playerRef={playerRef}
        hasSubtitles={Boolean(video.subtitles)}
        subtitlesMode={subtitlesMode}
        toggleSubtitles={toggleSubtitles}
      /> */}
    </Wrapper>
  );
};

Player.propTypes = {
  video: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Player;
