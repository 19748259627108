import styled from 'styled-components';

export const HeadingOne = styled.h1`
  font-size: ${({ theme }) => `calc(${theme.fontSize.xxl} - 4rem)`};
  font-weight: ${({ theme }) => theme.light};
  line-height: 1.15;
  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => `calc(${theme.fontSize.xxl} - 2.2rem)`};
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: ${({ theme }) => theme.fontSize.xxl};
  }
`;

export const HeadingTwo = styled.h2`
  font-size: ${({ theme }) => `calc(${theme.fontSize.xl} - 3.2rem)`};
  font-weight: 100;
  line-height: 1.2;
  padding: ${({ nopadding }) => (nopadding ? '0' : '0 0 15px')};
  ${({ theme }) => theme.mq.s} {
    font-size: ${({ theme }) => `calc(${theme.fontSize.xl} - 2.4rem)`};
    padding: ${({ nopadding }) => (nopadding ? '0' : '0 0 25px')};
  }
  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => `calc(${theme.fontSize.xl} - 1.8rem)`};
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: ${({ theme }) => `calc(${theme.fontSize.xl} - 0.6rem)`};
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 7.2rem;
    padding: ${({ nopadding }) => (nopadding ? '0' : '0 0 30px')};
  }
`;
