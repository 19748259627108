import styled, { css } from 'styled-components';

const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1770px;
  padding: 5px 15px;
  text-align: ${({ align }) => align || 'left'};
  ${({ theme }) => theme.mq.xs} {
    padding: 5px 20px;
  }
  ${({ theme }) => theme.mq.md} {
    padding: 5px 30px;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 5px 70px;
    max-width: 1850px;
  }
  ${({ secondary }) =>
    secondary &&
    css`
      max-width: 1640px;
    `}
  ${({ short }) =>
    short &&
    css`
      max-width: 1400px;
    `}
`;

export default Content;
