import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LazyImage from 'components/shared/LazyImage';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';

const InvestmentCardInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px 0 10px;
  ${({ theme }) => theme.mq.lg} {
    padding: 25px 0 10px;
  }
`;

const InvestmentTitle = styled.h3`
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.4;
  br {
    display: none;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.2rem;
  }
`;

const Time = styled(InvestmentTitle)`
  color: #ccc;
  font-weight: 300;
  white-space: nowrap;
  text-transform: uppercase;
`;

const ThumbImage = styled.div`
  width: 100%;
  height: 100%;
  transition: transform 0.7s ease 0s;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  &:hover {
    button {
      opacity: 1;
    }
    ${ThumbImage} {
      transform: scale(1.05);
    }
  }
`;

const CardInner = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
`;

const Thumbnail = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  ${({ theme }) => theme.mq.s} {
    height: 230px;
  }
  ${({ theme }) => theme.mq.xl} {
    height: 280px;
  }
  ${({ theme }) => theme.mq.xxl} {
    height: 320px;
  }
  overflow: hidden;
  z-index: 1;
  @media (max-width: 1360px) and (min-width: 575px) {
    &:before {
      content: none;
    }
  }
`;

const ThumbnailWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  cursor: pointer;
`;

const ThumbnailInnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const PlayButton = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%);
  background: transparent;
  z-index: 25;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const InvestmentCard = ({ investment, showModal }) => {
  return (
    <Wrapper
      onClick={() => investment.url && showModal({ url: investment.url })}
    >
      <Thumbnail>
        <ThumbnailWrapper>
          <ThumbnailInnerWrapper>
            <ThumbImage>
              <LazyImage src={investment.thumbnail} alt="thumbnail" cover />
            </ThumbImage>

            <PlayButton aria-label="play video">
              <PlayIcon />
            </PlayButton>
          </ThumbnailInnerWrapper>
        </ThumbnailWrapper>
      </Thumbnail>
      <CardInner>
        <InvestmentCardInfo>
          <InvestmentTitle
            dangerouslySetInnerHTML={{
              __html: `${investment.title}`,
            }}
          />
          <Time as="time">{investment.duration}</Time>
        </InvestmentCardInfo>
      </CardInner>
    </Wrapper>
  );
};

InvestmentCard.propTypes = {
  investment: PropTypes.objectOf(PropTypes.any).isRequired,
  showModal: PropTypes.func.isRequired,
};

export default InvestmentCard;
